export const environment = {
    production: true,
    firebase: {
      apiKey: 'AIzaSyCeLm1QDx4B95QAS8t6FqaskkY7oTrwLu4',
      authDomain: 'planning-poker-test-dc808.firebaseapp.com',
      projectId: 'planning-poker-test-dc808',
      storageBucket: 'planning-poker-test-dc808.appspot.com',
      messagingSenderId: '615966905854',
      appId: '1:615966905854:web:b2086e2a7cbef361755caf',
      measurementId: 'G-EX57RZ95RH',
    },
    recaptcha3SiteKey: '6LdiDNIkAAAAALpZO5d0gm2tHRrjH0bG8VsOLJkT',
    useEmulators: false,
    premiumPriceId: 'price_1MhxFgCG1hllVHnccUeyTdNX',
    premiumPriceIdUsd: 'price_1Ol6XVCG1hllVHncvvWD4qlA',
    powerUserPromoCode: 'promo_1O44WwCG1hllVHncTTEcBsWM',
    smallBundlePriceId: 'price_1OJHgwCG1hllVHnc4Ikzm1Jv',
    smallBundlePriceIdUsd: 'price_1Ol6QsCG1hllVHncRehAiDDL',
    largeBundlePriceId: 'price_1OJHhJCG1hllVHnchusIfXGT',
    largeBundlePriceIdUsd: 'price_1Ol6SICG1hllVHnckSSOmarj',
    megaBundlePriceId: 'price_1OJHhaCG1hllVHncOsDUJ3Ae',
    megaBundlePriceIdUsd: 'price_1Ol6TKCG1hllVHncyhXvJZ1K',
    orgBundlePriceId: 'price_1PmaW8CG1hllVHncND9hIWfd',
    orgBundlePriceIdUsd: 'price_1PmaWYCG1hllVHnca39K5Xzg',
    cloudProjectNumber: '615966905854',
  };
  